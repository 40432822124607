import ClipboardJS from "clipboard";
import $ from "jquery";

const policyView = () => {

    let languageSelect = document.querySelector('select[name="language"]');
    if (languageSelect) {
        languageSelect.addEventListener("change", function () {
            this.closest("form").submit();
        });
    }

    $(function () {
        // submit embed-options-form

        $("#embed-options-form").on("submit", function (e) {
            e.preventDefault();

            const formData = new FormData(e.target);
            const jsonData = {
                embed_styles: formData.get("embed_styles"),
            };
            const token = e.target.dataset.token;
            const csrfToken = $('meta[name="csrf-token"]').attr("content");

            $("#widget-toggle-alert").hide();

            $.ajax({
                type: "POST",
                url: "/toggle-policy-embed-style/" + token,
                headers: {
                    "X-CSRF-TOKEN": csrfToken,
                },
                data: jsonData,
                dataType: "json",
                success: function (response) {
                    $("#widget-toggle-alert .alert")
                        .html(response.message)
                        .toggleClass(["alert--success", "alert--error"], false)
                        .toggleClass("alert--" + response.status, true);
                    $("#widget-toggle-alert").show();
                },
                error: function (xhr) {
                    const error = xhr.responseJSON;
                    $("#widget-toggle-alert .alert")
                        .html(error.message)
                        .toggleClass("alert--success", false)
                        .toggleClass("alert--error", true);
                    $("#widget-toggle-alert").show();
                    console.error("An error occurred: " + error.message);
                },
            });
        });

        // Document tabs.
        $(".tabs__trigger").on("click", function (e) {
            var trigger = $(this);
            var tabID = trigger.data("tab");

            if (tabID) {
                e.preventDefault();
                $(".tabs__trigger").removeClass("is-active");
                trigger.addClass("is-active");

                $(".tabs__panel").removeClass("is-active");
                $("#" + tabID).addClass("is-active");
                $(".tabs__triggers").removeClass("is-open");

                const siblingsTabsAndMe = trigger
                    .parent()
                    .children()
                    .not(".tabs__dropdown");
                const activeIndex = siblingsTabsAndMe.index(trigger);

                trigger
                    .closest(".tabs__triggers")
                    .toggleClass("tabs__triggers--first", activeIndex === 0)
                    .toggleClass(
                        "tabs__triggers--last",
                        activeIndex === siblingsTabsAndMe.length - 1
                    );
            }
        });

        $(".tabs__dropdown").on("click", function () {
            if (
                $(this)
                    .parent()
                    .hasClass("is-open")
            ) {
                $(this)
                    .parent()
                    .removeClass("is-open");
            } else {
                $(this)
                    .parent()
                    .addClass("is-open");
            }
            return false;
        });

        // Plaintext/HTML triggers.
        $(".filter__trigger[data-filter]:not(.disabled)").on("click", e => {
            e.preventDefault();

            var trigger = $(e.currentTarget);
            var filterID = trigger.data("filter");

            var triggers_container = trigger.closest(".filter");
            var panels_container = triggers_container
                .parent()
                .find(".filter__panels");

            triggers_container
                .find(".filter__trigger")
                .removeClass("is-active");
            trigger.addClass("is-active");

            panels_container.find(".filter__panel").removeClass("is-active");
            $("#" + filterID).addClass("is-active");
        });

        $(".filter__dropdown").on("click", e => {
            e.preventDefault();

            var trigger = $(e.currentTarget);
            trigger.toggleClass("is-active");
        });

        $(".filter__dropdown__panel .filter__trigger").on("click", e => {
            var trigger = e.currentTarget.innerHTML;
            $(".filter__dropdown__trigger .bucket")[0].innerHTML = trigger;
        });

        $(".js-embed").on("click", e => {
            console.log('clicked policy-view.js line 136');
            e.preventDefault();
            // Use closest to find the nearest ancestor button element
            const button = $(e.target).closest("button.js-embed");

            // Check if a button element was found
            if (button.length) {
                // Access data attributes from the button element
                const token = button.data("token");
                const document = button.data("document");
                const language = button.data("language");
                const type = button.data("type");

                const csrfToken = $('meta[name="csrf-token"]').attr("content");
                const headers = {
                    "X-CSRF-TOKEN": csrfToken,
                };

                $.ajax({
                    type: "POST",
                    url: "/fetch-embed-modal",
                    data: {
                        token: token,
                        document: document,
                        language: language,
                        type: type,
                    },
                    headers: headers,
                })
                    .done(function (data) {
                        $("#installation-instructions h4:first").html(
                            data.heading
                        );
                        $("#code-inner").text(data.embed);
                        $(".modal").toggleClass("is-active");
                        $("#widget-toggle-alert").hide();
                    })
                    .fail(function (xhr) {
                        // do something
                    });
            } else {
                console.warn("No parent button element found.");
            }
        });

        // Clipboard
        var clipboard = new ClipboardJS(".js-clipboard:not(.disabled)", {
            text: function (trigger) {
                var $trigger = $(trigger);
                var scope = $trigger
                    .closest(".filter")
                    .siblings(".filter__panels");
                var text = "";

                if (
                    scope
                        .find(".filter__panel.is-active")
                        .hasClass("content-visual")
                ) {
                    text = scope.find(".filter__panel.is-active").html();
                } else {
                    text = scope.find(".filter__panel.is-active pre").html();
                }

                // Replace <br> tags with \n to preserve line breaks
                text = text.replace(/<br\s*\/?>/gi, '\n');

                // Replace <p> tags with \n to preserve paragraph breaks
                text = text.replace(/<\/p>/gi, '\n').replace(/<p>/gi, '');

                // Replace header tags with \n to preserve header breaks
                text = text.replace(/<\/h[1-6]>/gi, '\n').replace(/<h[1-6][^>]*>/gi, '');

                // Replace <li> tags with \n to preserve list item breaks
                text = text.replace(/<\/li>/gi, '\n').replace(/<li>/gi, '');

                // Remove any remaining HTML tags
                text = text.replace(/<\/?[^>]+(>|$)/g, "");

                // Decode HTML entities
                var tempElement = document.createElement("div");
                tempElement.innerHTML = text;
                text = tempElement.textContent || tempElement.innerText;

                return text;
            },
        });

        clipboard.on("success", function () {
            var message = $(
                ".tabs__panel.is-active .filter__panels .clipboard-message"
            );

            message.addClass("is-active");
            setTimeout(function () {
                message.removeClass("is-active");
            }, 1500);
        });

        clipboard.on("error", function (e) {
            console.log(e);
        });

        document.querySelectorAll('.js-download-pdf').forEach(function (element) {
            element.addEventListener('click', function (e) {
                e.preventDefault(); // Prevent the default anchor behavior
                const token = this.getAttribute('data-token');
                const language = this.getAttribute('data-language');
                const slug = this.getAttribute('data-slug');
                const content = document.querySelector(`#filter-${slug}-visual`).innerHTML;

                fetch(`/download-pdf/${token}/${language}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    },
                    body: JSON.stringify({contents: content})
                })
                    .then(response => response.blob())
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = 'document.pdf';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(error => console.error('Error:', error));
            });
        });

    });
};

policyView();

export default policyView;
